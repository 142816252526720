<template>
  <div>
    <header-slot no-border-bottom :clients-search="true" :global-search="true">
      <template #actions>
        <div class="w-100 d-flex justify-content-end" style="gap: 15px">
          <b-input-group>
            <b-input-group-prepend>
              <b-button variant="outline-info">GLOBAL SEARCH</b-button>
            </b-input-group-prepend>
            <b-form-input
              v-model="searchText"
              @keyup.enter="search"
              placeholder="Search by Name, Account or the last 4 digits of Phone number"
              style="height: auto"
            />
            <b-input-group-append>
              <b-button variant="primary" @click="search">
                <feather-icon icon="SearchIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <b-button
            variant="success"
            class="btn-black"
            @click="openModalNewLetter"
          >
            <feather-icon icon="PlusIcon" /><span>Create</span>
          </b-button>
        </div>
      </template>
    </header-slot>

    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: $route.matched[1].meta.routeDirectPending }"
        exact
        :active="isPendingTab"
        :link-classes="['px-3', bgTabsNavs]"
      >
        PENDING
      </b-nav-item>

      <b-nav-item
        :to="{ name: $route.matched[1].meta.routeDirectCompleted }"
        exact
        :active="isCompleteTab"
        :link-classes="['px-3', bgTabsNavs]"
      >
        COMPLETED
      </b-nav-item>

      <b-nav-item
        :to="{ name: $route.matched[1].meta.routeDirectToDeleted }"
        exact
        :active="isDeletedTab"
        :link-classes="['px-3', bgTabsNavs]"
      >
        DELETED
      </b-nav-item>
    </b-nav>

    <b-card no-body class="border-table-radius px-0">
      <router-view :key="$route.name" />
    </b-card>

    <modal-create-round-letter
      v-if="modalNewLetters"
      :direct="true"
      @updateTable="closeModalLetter"
      @hideModal="modalNewLetters = false"
    />

    <modal-global-search
      v-if="showModalGlobalSearch"
      :search-text="searchText"
      :program-id="programId"
      :role-id="currentUser.role_id"
      @closeModal="showModalGlobalSearch = false"
    />
  </div>
</template>
<script>
// STORE
import { mapGetters, mapActions } from "vuex";

// MODALS
import ModalCreateRoundLetter from "@/views/specialist-digital/views/cr-realtor/components/modal/ModalCreateRoundLetter.vue";
import ModalGlobalSearch from "@/views/commons/components/clients/modals/ModalGlobalSearch.vue";

export default {
  components: {
    ModalCreateRoundLetter,
    ModalGlobalSearch,
  },
  data() {
    return {
      modalNewLetters: false,
      searchText: "",
      showModalGlobalSearch: false,
      programId: 7,
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      pendingDirectRounds:
        "SpecialistDirectRoundStore/G_COUNTER_PENDING_DIRECT_ROUND_SPECIALISTS",
    }),
    isPendingTab() {
      const { routeDirectPending } = this.$route.matched[1].meta;
      return routeDirectPending == this.$route.name;
    },
    isCompleteTab() {
      const { routeDirectCompleted } = this.$route.matched[1].meta;
      return routeDirectCompleted == this.$route.name;
    },
    isDeletedTab() {
      const { routeDirectToDeleted } = this.$route.matched[1].meta;
      return routeDirectToDeleted == this.$route.name;
    },
  },
  methods: {
    ...mapActions({
      A_SET_COUNTER_DIRECT_ROUND_SPECIALIST:
        "SpecialistDirectRoundStore/A_SET_COUNTER_DIRECT_ROUND_SPECIALIST",
      A_GLOBAL_SEARCH_CLIENTS: "clients-store/A_GLOBAL_SEARCH_CLIENTS",
    }),
    openModalNewLetter() {
      this.modalNewLetters = true;
    },
    closeModalLetter() {
      this.modalNewLetters = false;
    },
    async search() {
      this.showModalGlobalSearch = true;
    },
  },
};
</script>
